.service-container {
  width: 100%;
  height: 490px;
  padding: 20px;
  box-sizing: border-box;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: 1px solid black;
}

.swiper-slide:hover {
  transform: scale(1.05);
  background-color: var(--primary-color);
  color: white;
  border: 1px solid white;
}

.slide-content {
  text-align: center;
  padding: 20px;
}

.slide-content img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.slide-content h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.slide-content p {
  font-size: 1rem;
}



@media only screen and (max-width: 768px) {
  .swiper-slide {
    margin: 0 auto;
  }
  .slide-content {
    padding: 15px;
  }
  .slide-content h3 {
    font-size: 1.2rem;
  }
  .slide-content p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 425px) {
  .service-container {
    width: 100%;
    height: 450px;
    padding: 20px;
    box-sizing: border-box;
  }
  

  .swiper-slide {
    margin: 0 ;
  }
  .slide-content {
    padding: 10px;
  }
  .slide-content h3 {
    font-size: 1rem;
  }
  .slide-content p {
    font-size: 1rem;
  }

  .padser {
    padding: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .service-container {
    width: 100%;
    height: 400px;
    box-sizing: border-box;
  }
  .swiper-slide {
    margin: 0 auto;
  }
  .slide-content {
    padding: 5px;
  }
  .slide-content h3 {
    font-size: 0.9rem;
  }
  .slide-content p {
    font-size: 0.9rem;
  }

  .padser {
    padding: 10px;
  }

}

@media only screen and (max-width: 320px) {
  .swiper-slide {
    margin: 0 auto;
  }
  .slide-content {
    padding: 5px;
  }
  .slide-content h3 {
    font-size: 0.8rem;
  }
  .slide-content p {
    font-size: 0.8rem;
  }

  .padser {
    padding: 0px;
  }

}
