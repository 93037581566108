.revenue__box, .orders__box, .products__box, .users__box {
    padding: 20px;
    border-radius: 5px;
}

.revenue__box {
    background: var(--card-bg-01);
}

.products__box {
    background: var(--card-bg-03);
    width: 80%;
}

.orders__box {
    background: var(--card-bg-04);
}

.users__box {
    background: var(--card-bg-04);
    width: 80%;
}

.revenue__box h5, 
.orders__box h5, 
.products__box h5, 
.users__box h5{
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 20px;
}

.revenue__box span, 
.orders__box span,
.products__box span,
.users__box span  {
    color:  var(--primary-color);
    font-weight: 600;
    font-size: 2rem;
}

.dash {
    display: flex;
    width: 100%;
    text-align: center;
}

.search{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
   
}

.search__box {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding-right: 12px;
    padding-left: 2px;
    cursor: pointer;

}

.search__box input {
    width: 60%;
    border: none;
    outline: none;
    padding: 8px 10px;
}

.search__box span {
    color: var(--primary-color);
}

.table img {
    width: 100px;
    height:100px
}

@media only screen and (max-width: 425px) {
    
.search__box {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding-right: 12px;
    padding-left: 2px;
    cursor: pointer;

}
}


@media only screen and (max-width: 375px) {
    
    .search__box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--primary-color);
        border-radius: 5px;
        padding-right: 12px;
        padding-left: 2px;
        cursor: pointer;
    
    }
    }

    .admin__menu {
        background-color: #f8f9fa;
        padding: 20px 0;
    }
    
    .admin__menu-list {
        list-style-type: none;
        padding: 0;
    }
    
    .admin__menu-item {
        padding: 10px 0;
    }
    
    .admin__menu-item a {
        text-decoration: none;
        color: #333;
        font-weight: 500;
    }
    
    .active__admin-menu {
        color: #007bff;
    }
    
    .nav__icons {
        display: flex;
        justify-content: flex-end;
    }
    
    .mobile__menu {
        display: none;
    }
    
    .admin__navigation.active__menu {
        display: block;
    }
    
    @media (max-width: 768px) {
        .mobile__menu {
            display: block;
        }
        .admin__navigation {
            display: none;
        }
    }
    
    .form__group {
        margin-bottom: 15px;
    }
    
    input[type="file"],
    input[type="text"],
    textarea {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }
    
    textarea {
        resize: none;
        height: 150px;
    }
    
    .btn__submit {
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .btn__submit:hover {
        background-color: #0056b3;
    }
    