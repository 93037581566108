.hero__section{    background:url('../assets/images/banner/b1.jpeg') no-repeat center center;
    background-size: cover;
    padding: 100px 0px;
    display: flex;
    align-items: center;
    height: 500px;

}

.padreal {
    padding: 40px;
}

.discute {
   padding-top: 80px;
   
    
}

.pub span {
    font-size: 1.2rem;
}


.cont {
    padding: 10px;
}

.carousel-inner img{
    width: 100% !important;
    height: 500px;
    left: 0;
}
        
  

  .card-title {
    font-weight: 500;
    font-size: 10px;
   }

  .offre h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
  }


.hero__section
 {
   padding: 0px;
}

.hero__section h2 {
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 600;
    margin: 20px 0px;
}

.hero__section p {
    color: var(--primary-color);
    line-height: 28px;

}

.hero__sectionp:nth-child(1) {
    font-weight: 500;
}

.butt {
    margin-top: 50px;
}

.button-link {
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 8px;
    background-color: var(--primary-color);
    color: white;
    border: 1px solid transparent;
    transition: 0.5s ease;
}

.button-link:hover {
    background: transparent;
  color: var(--primary-color);
  border: 1px solid white;
}

.hero__content {
    padding-top: 45px;
}

.timer__count {
    background: var(--primary-color);
    height: 300px;
}

.timer__count img {
    width: 70% !important;
    height: 70% !important;
    object-fit: contain;
}

.store__btn {
    background: #fff;
    color: var(--primary-color);
    font-weight: 600;
}

.store__btn a:hover {
    color: var(--primary-color);
}

.realisation h1{
    font-weight: 600;
}

  /***********************Affiche et change de text **/

  .animated-text{
    font-size: 34px;
    font-weight: 600;
    min-width: 280px;
    color: white;
}



@media only screen and (max-width: 992px) {
    .hero__content h2 {
        font-size: 2rem;
    }

    .section.title{
        font-size: 1.7rem;
    }

    .counter__img{
        display: none;
    }

    .clock__top__content {
        text-align: center;
    }

    .clock__top__content h4 {
        font-size: 0.9rem !important;
    }

    .clock__top__content h3{
        font-size: 1rem !important;
    }

    .count__down-col {
        text-align: center;
       
    }

     
  .pagination a{
    font-size: 14px;
    padding: 8px;
    color: var(--primary-color);
    
  }
   
.videosrc video{
    width: 80%;
    height: 70%;
    border-radius: 10px;
  }
}


@media only screen and (max-width: 768px) {
    .hero__content h2 {
        font-size: 1.6rem;
    }

    .hero__content p {
        font-size: 0.6rem;
    }

    .buy__btn a{
        font-size: 0.9rem;
    }

    .section__title{
        font-size: 1.4rem;
    }

    .animated-text{
        font-size: 20px;
        font-weight: 600;
        min-width: 280px;
        color: white;
        padding-bottom: 20px;
    }

    .card {
    margin-bottom: 20px;
    }

    .__section {
        padding: 70px 0px;
    }

    
}

@media only screen and (max-width: 576px) {
    .hero__content {
        padding-top: 0;
        margin-bottom: 40px;

    }

    .imghome img{
        padding: 20px;
    }

    .discute {
        padding: 20px;
    }

    
.pub span {
    font-size: 1rem;
}

.padreal h1{
   font-size: 1.4rem;
   font-weight: 600;
}
    
.button-link button{
    border-radius: 10px;
    font-size: 14px;
    padding: 8px 6px;
    background-color: var(--primary-color);
    color: white;
    border: 1px solid transparent;
    transition: 0.5s ease;
}

   
.videosrc video{
    width: 100%;
    height: 90%;
    border-radius: 10px;
  }


  .offre h2 {
    font-size: 25px;
    font-weight: 700;
  }

}


  h6{
     
    font-size: 24px;
    color: rgb(11, 11, 37) ;
  }
  
  
video{
  width: 50%;
  height: 50%;
  border-radius: 10px;
}

/******************Apparaitre ***************/

  
  
  
  

