/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

/*  css variables */
:root {
  --card-bg-01: #fdefe6;
  --card-bg-02: #d6e5fb;
  --card-bg-03: #ceebe9;
  --card-bg-04: #e2f2b2;
  --primary-color: #050747c7;
  --primary-color1: #000000;
  --hero-bg: #d6e5fb;
  --heading-fontSize: 2rem;
  --small-text-color: #615d5d;
  --heading-text-color: #0a1d37;
}

/* reset browser default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: #f7f7fc;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

p {
  font-size: 1rem;
 
}

h1,
h2 {
  font-size: var(--heading-fontSize);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

img{
  width: 100%;
  transition: transform 0.3s ease;
}

section {
  padding: 60px 0px;
}

.section__title{
  color: var(--primary-color);
  font-weight: 600;
}


@media only screen and (max-width: 576px) {
  .service__item {
      padding: 20px;
  }
}